import { db } from '../services/firebase'
import {
  CompanyWallet,
  CryptoNode,
  MemberSwapWallet,
  MemberWallet,
  useDatabaseObject,
  WalletTransaction,
} from '../hooks/database'
import { useUid } from '../hooks/auth'

function getWalletId(userId: string, currency: string): string {
  return `${userId}__${currency}`
}

export function useMemberWallet(userId?: string | null, currency?: string): [MemberWallet | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersWallets').child(walletId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useMemberSwapWallet(userId?: string | null, currency?: string): [MemberSwapWallet | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersSwapWallets').child(walletId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useMemberWalletAddress(userId?: string | null, currency?: string): [string | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersWallets').child(walletId).child('address') : undefined)
  return [v.value, v.loading, v.error]
}

export function useWalletTransaction(transactionId?: string | null): [WalletTransaction | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(transactionId ? db.child('walletsTransactions').child(transactionId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useStakeCryptoNodeStatus(): [CryptoNode | undefined, boolean, object | undefined] {
  const [uid] = useUid()
  const v = useDatabaseObject(uid ? db.child('cryptoNodes').child('cake-bsc-dapp') : undefined)
  return [v.value, v.loading, v.error]
}

export function useCompanyWallet(
  type?: 'stake' | 'swap',
  name?: string,
  symbol?: string
): [CompanyWallet | undefined, boolean, object | undefined] {
  const [uid] = useUid()
  const walletId = type && name && symbol ? `inkryptusSwap__${name}__${symbol}` : undefined
  const v = useDatabaseObject(uid && walletId ? db.child('companyWallets').child(walletId) : undefined)
  return [v.value, v.loading, v.error]
}





