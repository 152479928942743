import React from 'react'

const LicensesPage: React.FunctionComponent = () => {
  return (
    <div className="full-width box-licenses">
      <div>
    
      </div>
    </div>
  )
}

export default LicensesPage
