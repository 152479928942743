import React from 'react'
import { Row, Col, Card } from 'antd'
import {  decimal,  sig, trunc } from '@allcoinwallet/invest-bitcoin-decimal'

import { useCompanyWallet } from '../../modules/wallets'

export interface MembersBalanceWidgetsProps {
  type: 'stake' | 'swap'
  symbol: string
  onVaultWithdrawalClick?: (type: 'stake' | 'swap', symbol: string) => void
  onLockTransactionsListClick?: (type: 'stake' | 'swap', symbol: string, lockTransactions: { [transactionId: string]: decimal }) => void
  onAvailableBalanceOwnersListClick?: (type: 'stake' | 'swap', symbol: string, availableOwners: { [userId: string]: decimal }) => void
}



const MembersBalanceWidgets: React.FC<MembersBalanceWidgetsProps> = ({
  type,
  symbol,
  onVaultWithdrawalClick,
  onLockTransactionsListClick,
  onAvailableBalanceOwnersListClick,
}) => {
  const [vaultWallet] = useCompanyWallet(type, 'vault', symbol)


  const availableVaultBalance = vaultWallet?.balance.available




  return (
    <>
      {availableVaultBalance && sig(trunc(availableVaultBalance, 8)) > 0 && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Card >
         
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
         
        </Col>
        <Col span={8}>
          
        </Col>
        <Col span={8}>
         
        </Col>
      </Row>
    </>
  )
}

export default MembersBalanceWidgets
