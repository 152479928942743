import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AdminLayout from './../layouts/admin'

import HomePage from '../pages/home'
// import EarningsPage from '../pages/earnings'

import LicensesPage from '../pages/licenses'
import MembersPage from '../pages/members'
import ProductsContractsPage from '../pages/products-contracts'
import WalletsTransactionsPage from '../pages/wallets-transactions'
import SwapWalletsTransactionsPage from '../pages/swap-wallets-transactions'

interface Props {}

const DeskRoutes: React.FC<Props> = () => {
  return (
    <AdminLayout>
      <Switch>
        <Route exact path="/" component={HomePage} />
        {/* <Route exact path="/earnings" component={EarningsPage} /> */}

        <Route exact path="/licenses" component={LicensesPage} />
        <Route exact path="/members" component={MembersPage} />
        <Route exact path="/products-contracts" component={ProductsContractsPage} />
        <Route exact path="/wallets-transactions" component={WalletsTransactionsPage} />
        <Route exact path="/swap-wallets-transactions" component={SwapWalletsTransactionsPage} />
        <Redirect path="/" to="/" />
      </Switch>
    </AdminLayout>
  )
}

export default DeskRoutes
